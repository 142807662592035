import React from 'react';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QTableRow } from '../Q-Components/QTableRow';
import { QTableCell } from '../Q-Components/QTableCell';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QButton } from '../Q-Components/QButton';
import util from '../../logic/util';
import { QLink } from '../Q-Components/QLink';
import { QUncontrolledTooltip } from '../Q-Components/QUncontrolledTooltip';

const AppStoreApp = ({ app, submitApp, index }) => {
  const id = `app-result-${index}`;
  const { mastV2 } = useFlags();
  const excludedAppTooltipText = app.denySubmit
    ? app.bundleId?.substring(0, 9) === 'com.apple' ||
      app.sellerName === 'Apple Inc.'
      ? 'Apple First Party Apps cannot be submitted'
      : 'App cannot be submitted'
    : '';

  return (
    <QTableRow>
      {/* App */}
      <QTableCell>
        <QRow id={id}>
          <QCol flex="1 1" xs="3">
            <a href={app.trackViewUrl} target="_blank">
              <img
                alt="Icon"
                src={app.artworkUrl60}
                className="store-search-app-icon"
              />
            </a>
          </QCol>
          <QCol flex="3 3" xs="9">
            <QRow>
              <strong>{app.trackName}</strong>
            </QRow>
            <QRow className="dont-break-out" class="dont-break-out">
              <i>{app.bundleId}</i>
            </QRow>
          </QCol>
        </QRow>
      </QTableCell>

      {/* Developer */}
      <QTableCell>
        <QLink href={app.sellerUrl} target="_blank">
          {app.sellerName}
        </QLink>
      </QTableCell>

      {/* Release Date */}
      <QTableCell>
        {moment(app.currentVersionReleaseDate).format('L - LTS')}
      </QTableCell>

      {/* Description */}
      <QTableCell className="dont-break-out" class="dont-break-out">
        {util.getSubstringIfNeeded(app.description, 515)}
      </QTableCell>

      {/* Actions */}
      <QTableCell>
        {mastV2 ? (
          <QUncontrolledTooltip
            placement="bottom"
            title={excludedAppTooltipText}
            data-testid="tooltip-div"
          >
            <div>
              <QButton
                variant="outlined"
                onClick={() =>
                  submitApp(
                    app.trackName,
                    app.bundleId,
                    app.artworkUrl60,
                    'ios',
                  )
                }
                disabled={app.denySubmit}
                startIcon={<i className="fa-solid fa-upload" />}
              >
                Submit
              </QButton>
            </div>
          </QUncontrolledTooltip>
        ) : (
          <div>
            <QButton
              id={`excludedApp-${index}`}
              className="btn-success"
              onClick={() =>
                submitApp(app.trackName, app.bundleId, app.artworkUrl60, 'ios')
              }
              disabled={app.denySubmit}
            >
              <i className="fa-regular fa-cloud-arrow-down fa-lg" />
            </QButton>
            {app.denySubmit && (
              <QUncontrolledTooltip
                placement="bottom"
                target={`excludedApp-${index}`}
                title={excludedAppTooltipText}
              />
            )}
          </div>
        )}
      </QTableCell>
    </QTableRow>
  );
};

export default AppStoreApp;
