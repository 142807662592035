import React from 'react';
import { useSelector } from 'react-redux';
import { ExpandMore } from '@mui/icons-material';
import { withLDConsumer, useFlags } from 'launchdarkly-react-client-sdk';

import i18n from '../../localization/i18n';
import { QUncontrolledButtonDropdown } from '../Q-Components/QUncontrolledButtonDropdown';
import { QButton } from '../Q-Components/QButton';
import { QDropdownToggle } from '../Q-Components/QDropdownToggle';
import { QMenuButtonWithIcon } from '../Q-Components/QMenuButtonWithIcon';
import { QDropdownMenu } from '../Q-Components/QDropdownMenu';
import { QDropdownSelectItem } from '../Q-Components/QDropdownSelectItem';
import { QActionSelect } from '../Q-Components/QActionSelect';

const LDWatchlistAppActions = ({
  app,
  searchApp,
  checkVersion,
  updateExternalId,
  deleteWatchlistApp,
}) => {
  const { mastV2 } = useFlags();

  const userAccess = useSelector(({ emmApp }) => emmApp.userAccess);
  const { customized_eid: customizedEid } = useSelector(
    ({ emmApp }) => emmApp.userConfig,
  );

  const handleFocus = event => {
    event.target.style.background = 'none';
  };

  const appActionOptions = [
    {
      value: 'search-watchlist-app',
      label: i18n.t('Search App Submissions'),
      icon: <i className="fa-regular fa-magnifying-glass primary-icon" />,
      onClick: searchApp,
    },
    ...(app.belongsToUser || userAccess.group_admin
      ? [
          {
            value: 'check-version',
            label: i18n.t('Check New Version'),
            icon: <i className="fa-solid fa-upload success-icon" />,
            onClick: checkVersion,
          },
        ]
      : []),
    ...(userAccess.change_app_external_id
      ? [
          {
            value: 'modify-eid',
            label: i18n.t(`Modify ${customizedEid || 'External ID'}`),
            icon: (
              <i
                className="fa-solid fa-pencil warning-icon"
                aria-hidden="true"
              />
            ),
            onClick: updateExternalId,
          },
        ]
      : []),
    ...(userAccess.group_admin
      ? [
          {
            value: 'delete-watchlist-app',
            label: i18n.t('Remove Watchlist App'),
            icon: <i className="fa-solid fa-xmark danger-icon" />,
            onClick: deleteWatchlistApp,
          },
        ]
      : []),
  ];

  return (
    <>
      {mastV2 ? (
        <QActionSelect actionOptions={appActionOptions} />
      ) : (
        <QUncontrolledButtonDropdown
          className="mx-1"
          title="Actions"
          sx={{ width: '140px' }}
          dropdown
          variant="filled"
        >
          <QDropdownToggle
            caret
            className="toggledown-primary"
            disableAnimation
            shrink={false}
            style={
              mastV2
                ? {
                    transform: 'none',
                    overflow: 'visible',
                  }
                : {}
            }
          >
            <QButton
              variant="outlined"
              endIcon={<ExpandMore />}
              className="button-toggledown-primary"
            >
              Actions
            </QButton>
          </QDropdownToggle>

          <QDropdownMenu
            variant="filled"
            IconComponent={null}
            value=""
            displayEmpty
            style={
              mastV2
                ? {
                    background: 'none',
                    paddingTop: '0px',
                    minWidth: 'unset',
                  }
                : {}
            }
            renderValue={() => {}}
            onFocus={handleFocus}
          >
            {/* First item is always selected in MUI by default so this is to get rid of that behavior. */}
            <QDropdownSelectItem value="" style={{ display: 'none' }} />

            <QDropdownSelectItem
              id="search-watchlist-app"
              title="Search App Submissions"
              onClick={searchApp}
            >
              <QMenuButtonWithIcon
                icon={
                  <i className="fa-regular fa-magnifying-glass primary-icon" />
                }
                label={i18n.t('Search App Submissions')}
              />
            </QDropdownSelectItem>

            {(app.belongsToUser || userAccess.group_admin) &&
              userAccess.can_submit && (
                <QDropdownSelectItem
                  id="check-version"
                  title="Check New App Version"
                  onClick={checkVersion}
                >
                  <QMenuButtonWithIcon
                    icon={<i className="fa-solid fa-upload success-icon" />}
                    label={i18n.t('Check New Version')}
                  />
                </QDropdownSelectItem>
              )}

            {userAccess.change_app_external_id && (
              <QDropdownSelectItem
                id="modify-eid"
                title="Modify EID"
                onClick={updateExternalId}
              >
                <QMenuButtonWithIcon
                  icon={
                    <i
                      className="fa-solid fa-pencil warning-icon"
                      aria-hidden="true"
                    />
                  }
                  label={i18n.t(`Modify ${customizedEid || 'External ID'}`)}
                />
              </QDropdownSelectItem>
            )}

            {userAccess.group_admin && (
              <QDropdownSelectItem
                id="delete-watchlist-app"
                title="Remove Watchlist App"
                onClick={deleteWatchlistApp}
              >
                <QMenuButtonWithIcon
                  icon={<i className="fa-solid fa-xmark danger-icon" />}
                  label={i18n.t('Remove Watchlist App')}
                />
              </QDropdownSelectItem>
            )}
          </QDropdownMenu>
        </QUncontrolledButtonDropdown>
      )}
    </>
  );
};

export const WatchlistAppActions = withLDConsumer()(LDWatchlistAppActions);
